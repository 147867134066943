// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dataprotection-js": () => import("./../../../src/pages/dataprotection.js" /* webpackChunkName: "component---src-pages-dataprotection-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourservices-baking-js": () => import("./../../../src/pages/ourservices/baking.js" /* webpackChunkName: "component---src-pages-ourservices-baking-js" */),
  "component---src-pages-ourservices-cooking-js": () => import("./../../../src/pages/ourservices/cooking.js" /* webpackChunkName: "component---src-pages-ourservices-cooking-js" */),
  "component---src-pages-ourservices-offlinemarketing-js": () => import("./../../../src/pages/ourservices/offlinemarketing.js" /* webpackChunkName: "component---src-pages-ourservices-offlinemarketing-js" */),
  "component---src-pages-ourservices-onlinemarketing-js": () => import("./../../../src/pages/ourservices/onlinemarketing.js" /* webpackChunkName: "component---src-pages-ourservices-onlinemarketing-js" */),
  "component---src-pages-ourservices-podcast-js": () => import("./../../../src/pages/ourservices/podcast.js" /* webpackChunkName: "component---src-pages-ourservices-podcast-js" */)
}

